import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

   // url:string = 'http://localhost:8007';
   // serviceAuthorisationURL:string = "http://localhost:8005";
  //
  url:string = 'https://uatgreenmonkeyservicecore.octanics.com';
  serviceAuthorisationURL:string = "https://uatgreenmonkeyserviceauthentication.octanics.com";
}

