import {Component, OnInit} from '@angular/core';
import {ConfigurationService} from "../../services/configuration.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Common} from "../../common/common";
import {Utility} from "../../common/utility";
import {Editeroptions} from "../../common/editeroptions";
import {Pricingdetails} from "../../models/pricingdetails.model";
import {LookUp} from "../../common/lookUp";

declare var $;

@Component({
  selector: 'app-updateproduct',
  templateUrl: './updateproduct.component.html',
  styleUrls: ['./updateproduct.component.css']
})
export class UpdateproductComponent implements OnInit {

  constructor(private configuration: ConfigurationService, private http: HttpClient,
              private router: Router, private route: ActivatedRoute, private lookup: LookUp) {
  }

  productForm: FormGroup;
  pricedetailForm: FormGroup;
  pricedetailindex: number;
  pricingId: number;
  currencyId: number;
  stripecode: number;
  pricingdetails: Array<Pricingdetails> = [];
  submitted: boolean = false;
  activeTab: string = 'productdetails';
  periods: any = [];
  productgroup: any = [];
  status: any = [];
  pricing: any = [];
  currency: any = [];
  product: any =[];
  id:number;

  showTab(tab: string): void {
    this.activeTab = tab;
  }

  getLookUp() {
    this.lookup.getLookUp('periods').subscribe((data) => {
      this.periods = data;
    });
    this.lookup.getLookUp('productgroup').subscribe((data) => {
      this.productgroup = data;
    });
    this.lookup.getLookUp('status').subscribe((data) => {
      this.status = data;
    });
    this.lookup.getLookUp('currency').subscribe((data) => {
      this.currency = data;
    });
  }

  insertPricedetails(pricedetail) {
    if (this.pricedetailForm.valid) {
      if (this.pricedetailindex !== undefined && this.pricedetailindex >= 0) {
        this.pricingdetails[this.pricedetailindex].pricing_id = $('#pricing_id option:selected').val() === undefined || $('#pricing_id option:selected').val() == '0: null' ? null : $('#pricing_id option:selected').val();
        this.pricingdetails[this.pricedetailindex].currency_id = $('#currency_id option:selected').val() === undefined || $('#currency_id option:selected').val() == '0: null' ? null : $('#currency_id option:selected').val();
        this.pricingdetails[this.pricedetailindex].price = $('#pricing_id option:selected').val() === 'null' || $('#pricing_id option:selected').val() == '0: null' ? null : $('#pricing_id option:selected').text();
        this.pricingdetails[this.pricedetailindex].currency = $('#currency_id option:selected').val() === 'null' || $('#currency_id option:selected').val() == '0: null' ? null : $('#currency_id option:selected').text();
        this.pricingdetails[this.pricedetailindex].stripecode = $('#stripecode').val();
        if ($('#pricedetail_id').val() > 0) {
          this.pricingdetails[this.pricedetailindex].entitystate = 'Modified';
        } else {
          this.pricingdetails[this.pricedetailindex].entitystate = 'Added';
        }
      } else {
        const customObj = new Pricingdetails();
        customObj.pricing_id = $('#pricing_id option:selected').val() === undefined || $('#pricing_id option:selected').val() == '0: null' ? null : $('#pricing_id option:selected').val();
        customObj.currency_id = $('#currency_id option:selected').val() === undefined || $('#currency_id option:selected').val() == '0: null' ? null : $('#currency_id option:selected').val();
        customObj.price = $('#pricing_id option:selected').val() === 'null' || $('#pricing_id option:selected').val() == '0: null' ? null : $('#pricing_id option:selected').text();
        customObj.currency = $('#currency_id option:selected').val() === 'null' || $('#currency_id option:selected').val() == '0: null' ? null : $('#currency_id option:selected').text();
        customObj.stripecode = $('#stripecode').val();
        customObj.entitystate = 'Added';
        this.pricingdetails.push(customObj);
      }
      this.clearPricingDetailControls();

    }
  }

  editPricingDetail(i, editdata) {
    console.log(editdata);
    $('#pricedetail_id').val(editdata.id);
    this.pricingId = editdata.pricing_id;
    this.currencyId = editdata.currency_id;
    this.stripecode = editdata.stripecode;
    this.pricedetailindex = i;
  }

  deletePricingDetail(id) {
    if (confirm('Are you sure you want to delete this record?')) {
      if (this.pricingdetails[id].id !== undefined) {
        this.pricingdetails[id].entitystate = 'Deleted';
      } else {
        this.pricingdetails.splice(id, 1);
      }
    }
  }

  clearPricingDetailControls() {
    $('#pricing_id').val('');
    $('#currency_id').val('');
    $('#stripecode').val('');
    $('#frmpricingdetail').get(0).reset();
    this.pricedetailindex = -1;
  }

  updateProduct() {
    if (this.productForm.valid) {
      let productData = this.productForm.value;
      productData.id = this.product.id;
      productData.pricingdetails = this.pricingdetails;
      this.http.post(this.configuration.url + '/updateproduct', productData).subscribe({
        next: (res: any) => {
          this.router.navigate(['/products']);
        },
        error: (err: any) => {
        }
      });
    }
  }

  getpricebycurrency(currencyid){
    this.http.get(this.configuration.url + '/getpricebycurrency?currencyid=' + currencyid).subscribe((data:any)=>{
      this.pricing = data;
    });
  }

  ngOnInit(): void {
    this.getLookUp();

    this.productForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      allowed_users: new FormControl('', Validators.required),
      version: new FormControl('', Validators.required),
      productgroup_id: new FormControl('', Validators.required),
      status_id: new FormControl('', Validators.required),
      period_id: new FormControl('', Validators.required),
      productcode: new FormControl('', Validators.required),
    });

    this.pricedetailForm = new FormGroup({
      pricing_id: new FormControl('', Validators.required),
      currency_id: new FormControl('', Validators.required),
      stripecode: new FormControl('', Validators.required)
    });

    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    const decriptedId = Common.decryptId(this.id);
    this.http.get(this.configuration.url + '/productdetails?id=' + decriptedId).subscribe(data => {
      this.product = data['productdetail'];
      this.pricingdetails = data['pricingdetail'];
    });
    $('#description').froalaEditor({
      key: Utility.editorLicenseKey,
      height: 200,
      placeholderText: "<br/>",
      toolbarButtons: Editeroptions.editerOptions()
    });

  }

}
