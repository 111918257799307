import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Route, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Common} from "../../common/common";
import {ConfigurationService} from "../../services/configuration.service";
import * as CryptoJS from 'crypto-js';


@Component({
  selector: 'app-userdetails',
  templateUrl: './userdetails.component.html',
  styleUrls: ['./userdetails.component.css']
})
export class UserdetailsComponent implements OnInit {

  constructor(private configuration:ConfigurationService,private http:HttpClient,private route:ActivatedRoute,private router:Router) { }
  details = {};
  email:any;
  users:any;
  userdetails = {};


  deleteData(email:any)
  {
    if (confirm("Are you sure you want to delete this record?"))
    {
      this.users={
        'email':email,
        'deleted_by':1
      }
      this.http.post(this.configuration.url + '/Deleteuserdata',this.users).subscribe((res:any)=>{
        this.router.navigate(['/users']);
      })
    }
  }


  ngOnInit() {
    this.route.params.subscribe(params => {
      this.email = params;
    });
    const bytes  = CryptoJS.AES.decrypt(this.email.id, 'secret key 123');
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    this.http.get(this.configuration.url + '/viewuserdetails?email=' + originalText).subscribe((data:any)=> {
      this.details = data.billingdetail;
      this.userdetails = data.userdetail;
    });
  }

}
