import { Component, OnInit } from '@angular/core';
import {ConfigurationService} from "../../services/configuration.service";
import { HttpClient } from '@angular/common/http';
import {Router, ActivatedRoute} from '@angular/router';
import {Common} from "../../common/common";
import {DomSanitizer} from "@angular/platform-browser";
import {Pricingdetails} from "../../models/pricingdetails.model";

@Component({
  selector: 'app-productsview',
  templateUrl: './productsview.component.html',
  styleUrls: ['./productsview.component.css']
})
export class ProductsviewComponent implements OnInit {

  constructor(private configuration: ConfigurationService, private http: HttpClient, private router: Router, private route: ActivatedRoute, private sanitizer: DomSanitizer) { }

  product: any = [];
  id: any;
  activeTab: string = 'productdetails';
  pricingdetails: Array<Pricingdetails> = [];


  showTab(tab: string): void {
    this.activeTab = tab;
  }

  deleteProduct(id: any) {
    if (confirm('Are you sure you want to delete this record?')) {
      this.product = {
        'id': id,
        'deleted_by' : 'ritu',
      };
      this.http.post(this.configuration.url + '/deleteproduct', this.product).subscribe((res: Object) => {
        this.router.navigate(['/products']);
      });
    }
  }

  navigateToproductEdit(id)
  {
    const encryptedId = Common.encryptId(id);
    this.router.navigate(['/updateproduct', encryptedId]);
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    const encryptedId = Common.decryptId(this.id);
    this.http.get(this.configuration.url + '/productdetails?id=' + encryptedId).subscribe((data: any) => {
      this.product = data['productdetail'];
      this.pricingdetails = data['pricingdetail'];
      // this.product['description']= this.sanitizer.bypassSecurityTrustHtml(this.product['description'])

    });
  }

}
